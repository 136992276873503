import React from 'react'

import Page, { Section } from '../shared/page-commons'

export default function NotFoundPage() {
  return (
    <Page bgColor="surface">
      <Section textColumn>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Section>
    </Page>
  )
}
